import React from 'react'
import { graphql, PageRendererProps, useStaticQuery, Link } from 'gatsby'
import { ExcerptTitle, ExcerptContainer, Date } from '@/styles'
import { MarkdownRemark } from '@/graphql-types'
import Layout from "@/components/layout";

const Home: React.FunctionComponent<PageRendererProps> = (props) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            allMarkdownRemark(
                filter: { fields: { category: { eq: "blogs" }}, frontmatter: { access: { ne: "draft" }} }
                sort: { fields: [frontmatter___date], order: DESC }
                limit: 20
            ) {
                edges {
                    node {
                        excerpt
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            date(formatString: "MM-DD-YYYY")
                        }
                    }
                }
            }
        }
    `)

    // const siteData = data.site.siteMetadata
    const posts = data.allMarkdownRemark.edges

    return (
        <Layout location={ props.location }>
            {posts.map(({ node }: {node:MarkdownRemark}) => {
                const frontmatter = node!.frontmatter!
                const slug = node!.fields!.slug!
                const excerpt = node!.excerpt!

                return (
                  <ExcerptContainer key={slug}>
                    <Date>{frontmatter.date}</Date>
                    <ExcerptTitle>
                      <Link to={`/${slug}/`}>{frontmatter.title || "Untitled"}</Link>
                    </ExcerptTitle>
                    <p dangerouslySetInnerHTML={{ __html: excerpt }}></p>
                  </ExcerptContainer>
                );
            })}
        </Layout>
    )
}

export default Home